.training-v1 {
  .App {
    text-align: center;
  }

  .appFooter {
    display: flex;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 4px;
    height: 80px;
    background: white;
    border-top: 1px solid #dddbda;
  }

  .selectedFooterComponent {
    font-weight: 700;
  }

  header {
    height: 50px;
  }

  .cardlayout {
    margin-bottom: 80px;
    margin-top: 40px;
    padding: 0.5rem;
  }

  footer.appFooter a {
    color: #080707;
  }

  footer.appFooter div {
    position: relative;
    /*display:inline-block;*/
    display: inline-grid;
    margin: auto;
    width: 20%;
  }

  /*Smooth scrolling on iOS*/
  div {
    /* -webkit-overflow-scrolling: touch; */
  }

  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .slds-spinner_container {
    position: fixed !important;
  }

  .live-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    animation: pulse 1.5s infinite;
    margin-left: 5px;
}

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }
}
