.loginForm {
    margin: 6vh auto 0px auto;
    padding: 1.25rem;
    border-radius: 0.25rem;
    border: 1px solid #D8DDE6;
    color: #16325c;
    background: white;
    width:400px;
    max-width:90vw;
    text-align:left;
    padding-bottom:2.0rem;
}

.loginContent {
    text-align: center; 
    position: fixed; 
    left: 0; 
    top: 0; 
    width: 100%; 
    margin-top: 12vh;
    overflow: hidden;
    position: fixed;
}

.loginInput {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}


