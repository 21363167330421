.training-v2 {
  .App {
    text-align: center;
  }

  .header-footer-margin {
    margin-top: 50px;
    margin-bottom: 100px;
    background-color: red;
  }

  .appFooter {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 4px;
    height: 80px;
    background: white;
    border-top: 1px solid #dddbda;
  }

  .selectedFooterComponent {
    font-weight: 700;
  }

  header {
    height: 50px;
  }

  .cardlayout {
    /* margin-bottom: 80px; */
    /* margin-top: 42px; */
    padding: 0;
    margin: 0;
  }

  .slds-context-bar {
    z-index: 1000000;
  }

  footer.appFooter a {
    color: #080707;
  }

  footer.appFooter div {
    position: relative;
    /*display:inline-block;*/
    display: inline-block;
    margin: auto;
    width: 20%;
    overflow: hidden;
  }

  * {
    /* --webkit-overflow-scrolling: touch; */
    /* -webkit-overflow-scrolling: auto !important; */
  }

  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .slds-spinner_container {
    position: fixed !important;
  }

  .osg-table {
    /* position:fixed; */
    /* margin:0px 7px; */
    /* top:45px; */
    /* z-index:10; */
    /* left:0px; */
  }

  .osg-table td {
    vertical-align: baseline;
  }

  .osg-table table {
    margin-top: 0px;
    /* padding-top:100px; */
    /* background:red; */
    /* height: calc(100vh - 40px - 80px - 415px); */
    /* overflow: scroll; */
    /* display: inline; */
    /* width: calc(100vw - 16px); */
  }

  .osg-table .table-container {
    height: calc(100vh - 40px - 80px - 88px);
    overflow: scroll;
    /* display: inline; */
    /* width: calc(100vw - 16px); */
  }

  .details-data-table-cell {
    display: block;
  }

  .osg-table td {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /* tr + tr.header-row-date  {
    background:red;
  }
  td:not(.header-row-date) + tr.header-row-date {
    background:green;
  } */

  /* .header-row-date { */
  /* background:red; */
  /* } */

  .slds-page-header_joined {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  /* body {
    height: 1500vh;
  } */

  /* html, body, #root {
    --webkit-overscroll-behavior: contain;
    overscroll-behavior: contain;
  } */
}

// .test-overflow {
//   background: red;
//   /* height: 50vh; */
//   width: 50vw;

//   background: red;
//   left: 0vw;
//   height: 100vh;
//   position: fixed;
//   top: 0;
//   z-index: -1;
// }

// body,
// html {
// -webkit-overflow-scrolling: touch;
// transform: translateZ(0);
// }

.footer-component {
  cursor: pointer;

  &:hover figcaption {
    font-weight: 600;

    &.selectedFooterComponent {
      font-weight: 700;
    }
  }
}
